<template>
  <div>
    <Sidebar />
    <div class="parent-class text-center d-flex flex-column">
      <div style="margin-top: 10%">
        <h2 style="color: grey">{{ $t("message.payment-failed") }}</h2>
      </div>
      <v-spacer></v-spacer>
      <router-link to="/" style="text-decoration: none">
        <v-btn class="button-class" block color="indigo" style="color: white">
          {{ $t("message.btn-back") }}
        </v-btn>
      </router-link>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "Success",
  components: {
    Sidebar,
  },
  created() {
  },
  methods: {
  },
};
</script>

<style scoped>
.parent-class {
  height: 91vh !important;
}
.button-class {
  position: fixed;
  margin-bottom: 0px !important;
  height: 55px !important;
  bottom: 0px;
  color: white !important;
  border-radius: 0px !important;
  box-shadow: 0px !important;
}
</style>
